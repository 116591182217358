import React from 'react';
import Typography from '@mui/material/Typography';
import { formatMoney } from '../helpers';

const BusinessProfileTaxAppraisalColumns = [
  {
    field: 'direction',
    headerName: 'Dirección',
    type: 'string',
    sortable: true,
    filterable: false,
    headerAlign: 'center',
    align: 'center',
    flex: 1,
    renderCell: ({ row }) => (
      <Typography>{row.direction}</Typography>
    ),
  },
  {
    field: 'ubication',
    headerName: 'Ubicación',
    type: 'string',
    sortable: true,
    filterable: false,
    headerAlign: 'center',
    align: 'center',
    flex: 1,
    renderCell: ({ row }) => (
      <Typography>{row.ubication}</Typography>
    ),
  },
  {
    field: 'taxAppraisal',
    headerName: 'Avalúo Fiscal',
    type: 'string',
    sortable: true,
    filterable: false,
    headerAlign: 'center',
    align: 'center',
    flex: 0.5,
    renderCell: ({ row }) => (
      <Typography>${formatMoney(row.taxAppraisal)}</Typography>
    ),
  },
  {
    field: 'rol',
    headerName: 'Rol',
    type: 'string',
    sortable: true,
    filterable: false,
    headerAlign: 'center',
    align: 'center',
    flex: 0.5,
    renderCell: ({ row }) => (
      <Typography>{row.rol}</Typography>
    ),
  },
];

export default BusinessProfileTaxAppraisalColumns;
